import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect } from 'react'

import {
  AccountType,
  CurrentUserData,
  fetchCurrentUserData,
  fetchRoleDetails,
  setLastLoginType
} from '../../../requests'
import { routes } from '../../../routes'
import { changeLocale } from '../../../utils'
import { UserContext } from '../UserContext'

interface Props {
  children: React.ReactElement
  accountType?: AccountType
  authenticatedByCode?: boolean
}

export const AuthRoute: React.FC<Props> = ({
  children,
  accountType = AccountType.User,
  authenticatedByCode = false
}) => {
  const { user, setUserData } = useContext(UserContext)

  useEffect(() => {
    setLastLoginType(accountType)
  }, [accountType])

  const logout = () => {
    setUserData(null)
    navigate(routes.signIn(accountType))
  }

  const fetchRole = (user: CurrentUserData) => {
    fetchRoleDetails(
      { id: user.roleId },
      {
        onSuccess: roleDetails => {
          setUserData({ ...user, roleDetails })
        },
        onRequestError: logout
      }
    )
  }

  useEffect(() => {
    if (authenticatedByCode) return

    fetchCurrentUserData(
      { accountType },
      {
        onSuccess: (user: CurrentUserData) => {
          if (accountType === AccountType.User) fetchRole(user)
          else setUserData(user)

          if (accountType === AccountType.User) changeLocale(user.language)
        },
        onRequestError: logout
      }
    )
  }, [])

  return user.isAuthorized() || authenticatedByCode ? children : null
}
