import './EditSubjectEmail.less'

import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import DatacSuccess from '../../../assets/images/datac-success.svg'
import { useScopedIntl } from '../../../hooks'
import { editSubjectEmail, editSubjectEmailResend } from '../../../requests'
import { maxTitleLengthShort, validateEmail, validateRequired } from '../../../validation'
import { DatacIconButton, DatacMessage, DatacSettingsFormItem, DatacSubtitle, DatacTitle } from '../../common'
import { useSubjectDashboardStore } from '../SubjectDashboardStore'

export const EditSubjectEmail: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlDashboard = useScopedIntl('subject_dashboard')
  const intlSignupSuccess = useScopedIntl('auth.signup_success')
  const [formInstance] = Form.useForm()
  const [isEmailAlreadyUsed, setIsEmailAlreadyUsed] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isConfirmationSent, setIsConfirmationSent] = useState(false)
  const intlSubjects = useScopedIntl('studies.subjects')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const [formKey, setFormKey] = useState(0)
  const { subject } = useSubjectDashboardStore()

  useEffect(() => {
    if (subject) resetFields()
  }, [subject])

  const onSubmit = ({ email }: { email: string }) => {
    setIsSaving(true)
    editSubjectEmail(
      { email },
      {
        onSuccess: () => {
          setIsConfirmationSent(true)
          setIsSaving(false)
          setCurrentEmail(email)
        },
        onRequestError: code => DatacMessage.genericError(intl, code),
        onEmailAlreadyUsed: () => setIsEmailAlreadyUsed(true),
        onError: () => setIsSaving(false)
      }
    )
  }

  const onResend = () => {
    setIsSaving(true)
    editSubjectEmailResend(
      { email: currentEmail },
      {
        onSuccess: () => setIsSaving(false),
        onRequestError: code => DatacMessage.genericError(intl, code),
        onError: () => setIsSaving(false)
      }
    )
  }

  const resetFields = () => {
    formInstance.setFieldsValue({ email: subject.email })
    setCurrentEmail(subject.email)
    setFormKey(formKey === 0 ? 1 : 0)
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    formInstance.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  return (
    <div className="subject-dashboard-edit-email">
      {isConfirmationSent ? (
        <div className="subject-dashboard-edit-email__success">
          <DatacSubtitle type="h2">{intlSignupSuccess('subtitle')}</DatacSubtitle>
          <div>
            <DatacSuccess />
          </div>
          <div>{intlSignupSuccess('no_email')}</div>
          <DatacIconButton icon="arrowRight" iconPosition="end" loading={isSaving} onClick={() => onResend()}>
            {intlSignupSuccess('submit')}
          </DatacIconButton>
        </div>
      ) : (
        <>
          <div className="subject-dashboard-edit-email__controls">
            <DatacTitle type="h1">{intlDashboard('edit_email')}</DatacTitle>
            {isEditingOn ? (
              <div>
                <Button size="large" type="default" onClick={onCancel}>
                  {intl('common.cancel')}
                </Button>
                <Button size="large" type="primary" onClick={onSave}>
                  {intl('common.save')}
                </Button>
              </div>
            ) : (
              <Button size="large" type="primary" onClick={onEdit}>
                {intl('common.edit')}
              </Button>
            )}
          </div>
          <Form form={formInstance} onFinish={onSubmit} key={formKey} className="subject-dashboard-edit-email__form">
            <DatacSettingsFormItem
              name="email"
              label={intlSubjects('email.label')}
              validate={[
                validateRequired(intl('common.required')),
                validateEmail(intlSubjects('email.validation.wrong'))
              ]}
              error={isEmailAlreadyUsed ? intlSubjects('email.validation.taken') : undefined}
            >
              <Input
                disabled={!isEditingOn}
                size="large"
                placeholder={intlSubjects('email.placeholder')}
                maxLength={maxTitleLengthShort}
                onChange={() => setIsEmailAlreadyUsed(false)}
              />
            </DatacSettingsFormItem>
          </Form>
        </>
      )}
    </div>
  )
}
