import './AccountDetails.less'

import { Button, Form, Input } from 'antd'
import * as ibantools from 'ibantools'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  SubjectAccountDetails,
  editSubjectAccountDetails,
  fetchSubjectAccountDetails,
  fetchSubjectDashboard,
  localizedCountries
} from '../../../requests'
import { maxTitleLengthLong, validateIBAN, validateRequired, validateSWIFT } from '../../../validation'
import { UserContext } from '../../auth'
import { DatacMessage, DatacSelect, DatacSettingsFormItem, DatacTitle } from '../../common'

const IBAN_MAX_LENGTH = 34
const SWIFT_MAX_LENGTH = 11

const countrySpecs = ibantools.getCountrySpecifications()
const allowedCountryCodes = Object.keys(countrySpecs).filter(code => countrySpecs[code].IBANRegistry)

export const AccountDetails: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlAccount = useScopedIntl('subject_dashboard.account_details')
  const [formInstance] = Form.useForm()
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const { user } = useContext(UserContext)
  const [accountDetails, setAccountDetails] = useState<SubjectAccountDetails>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [needsBankDetails, setNeedsBankDetails] = useState(false)
  const [countryCode, setCountryCode] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    fetchSubjectDashboard({
      onSuccess: records => {
        if (!records.some(record => record.bankDetailsValidation)) {
          setNeedsBankDetails(false)
          return
        }
        setNeedsBankDetails(true)
        fetchSubjectAccountDetails({
          onSuccess: accountDetails => {
            setAccountDetails(accountDetails)
            setIsLoading(false)
          },
          onRequestError: code => {
            DatacMessage.genericError(intl, code)
            setIsLoading(false)
          }
        })
      },
      onRequestError: code => {
        setIsLoading(false)
        DatacMessage.genericError(intl, code)
      }
    })
  }, [])

  useEffect(() => {
    if (accountDetails) resetFields()
  }, [accountDetails])

  useEffect(() => {
    if (isLoading || !user.isPaymentsEnabled || !needsBankDetails) {
      return
    }
    const accountNumber = formInstance.getFieldValue('accountNumber')
    if (countryCode && accountNumber) formInstance.validateFields()
  }, [countryCode, isLoading, user.isPaymentsEnabled, needsBankDetails])

  const resetFields = () => {
    formInstance.setFieldsValue({
      countryCode: accountDetails.countryCode,
      accountBeneficiary: accountDetails.accountBeneficiary,
      accountNumber: accountDetails.accountNumber,
      swift: accountDetails.swift
    })
    setFormKey(formKey === 0 ? 1 : 0)
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    formInstance.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = ({ countryCode, accountBeneficiary, accountNumber, swift }: SubjectAccountDetails) => {
    setIsSaving(true)
    editSubjectAccountDetails(
      { countryCode, accountBeneficiary, accountNumber, swift },
      {
        onSuccess: accountDetails => {
          setAccountDetails(accountDetails)
          setIsEditingOn(false)
          setIsSaving(false)
        },
        onRequestError: code => {
          setIsSaving(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  const countries = useMemo(() => {
    const countryList = [
      ...localizedCountries(user.language)
        .map(country => ({ value: country.alpha2.toUpperCase(), label: country.name }))
        .filter(country => allowedCountryCodes.includes(country.value)),
      { value: 'XK', label: 'Kosovo' }
    ]
    countryList.sort((a, b) => {
      const nameA = a.label.toLowerCase()
      const nameB = b.label.toLowerCase()
      return nameA > nameB ? 1 : nameA < nameB ? -1 : 0
    })
    return countryList
  }, [])

  if (isLoading || !user.isPaymentsEnabled || !needsBankDetails) {
    return null
  }

  return (
    <div className="subject-dashboard-account-details">
      <div className="subject-dashboard-account-details__controls">
        <DatacTitle type="h1">{intlAccount('title')}</DatacTitle>
        {isEditingOn ? (
          <div>
            <Button size="large" type="default" onClick={onCancel}>
              {intl('common.cancel')}
            </Button>
            <Button size="large" type="primary" onClick={onSave} loading={isSaving}>
              {intl('common.save')}
            </Button>
          </div>
        ) : (
          <Button size="large" type="primary" onClick={onEdit}>
            {intl('common.edit')}
          </Button>
        )}
      </div>
      <Form form={formInstance} onFinish={onSubmit} key={formKey} className="subject-dashboard-account-details__form">
        <DatacSettingsFormItem
          name="countryCode"
          label={intlAccount('country.label')}
          validate={validateRequired(intlAccount('country.required'))}
        >
          <DatacSelect
            disabled={!isEditingOn}
            options={countries}
            showSearch
            onChange={value => setCountryCode(value)}
          />
        </DatacSettingsFormItem>
        <DatacSettingsFormItem
          name="accountBeneficiary"
          label={intlAccount('account_beneficiary.label')}
          validate={validateRequired(intl('common.required'))}
        >
          <Input disabled={!isEditingOn} size="large" maxLength={maxTitleLengthLong} />
        </DatacSettingsFormItem>
        <Form.Item noStyle dependencies={['countryCode']}>
          {({ getFieldValue }) => {
            const countryCode = getFieldValue('countryCode')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const validateCountryCode = (value: any) => {
              return String(value).startsWith(countryCode)
                ? Promise.resolve()
                : Promise.reject(intlAccount('account_number.validation.wrong'))
            }
            return (
              <DatacSettingsFormItem
                name="accountNumber"
                label={intlAccount('account_number.label')}
                validate={[
                  validateRequired(intl('common.required')),
                  validateIBAN(intlAccount('account_number.validation.wrong')),
                  validateCountryCode
                ]}
              >
                <Input disabled={!isEditingOn} size="large" maxLength={IBAN_MAX_LENGTH} />
              </DatacSettingsFormItem>
            )
          }}
        </Form.Item>
        <DatacSettingsFormItem
          name="swift"
          label={intlAccount('swift.label')}
          validate={[validateRequired(intl('common.required')), validateSWIFT(intlAccount('swift.validation.wrong'))]}
        >
          <Input disabled={!isEditingOn} size="large" maxLength={SWIFT_MAX_LENGTH} />
        </DatacSettingsFormItem>
      </Form>
    </div>
  )
}
