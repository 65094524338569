import React from 'react'

import { AccountDetails } from '../../components/SubjectDashboard/AccountDetails'
import { DeletionRequest } from '../../components/SubjectDashboard/DeletionRequest'
import { EditSubject } from '../../components/SubjectDashboard/EditSubject'
import { EditSubjectEmail } from '../../components/SubjectDashboard/EditSubjectEmail'
import { Layout } from '../../components/SubjectDashboard/Layout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { useScopedIntl } from '../../hooks'
import { AccountType } from '../../requests'

const IndexPage = () => {
  const intlSubjectDashboard = useScopedIntl('subject_dashboard')
  return (
    <AuthRoute accountType={AccountType.Subject}>
      <Layout title={intlSubjectDashboard('my_profile')} hasBackToDashboard hasChildrenInBoxes>
        <SessionTimeoutModal accountType={AccountType.Subject} />
        <EditSubject />
        <EditSubjectEmail />
        <DeletionRequest />
        <AccountDetails />
      </Layout>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Datacapt - Settings</title>
