import './EditSubject.less'

import { Button, Form, Input } from 'antd'
import { ConfigProvider } from 'antd-country-phone-input'
import * as locales from 'locale-codes'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { editSubjectDetails, localizedCountries } from '../../../requests'
import { changeLocale, localeFromPath } from '../../../utils'
import { maxTitleLengthShort, validatePhone, validateRequired } from '../../../validation'
import { UserContext } from '../../auth'
import { DatacMessage, DatacPhoneInput, DatacSelect, DatacSettingsFormItem, DatacTitle } from '../../common'
import { SubjectFormData } from '../../shared/SubjectForm'
import { useSubjectDashboardStore } from '../SubjectDashboardStore'

export const EditSubject: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlProfile = useScopedIntl('subject_dashboard.edit_profile')
  const intlSubjects = useScopedIntl('studies.subjects')
  const [formInstance] = Form.useForm()
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const { user, setUserLanguage } = useContext(UserContext)
  const { subject, setSubject } = useSubjectDashboardStore()

  useEffect(() => {
    if (user?.language) changeLocale(user.language)
  }, [user.language])

  useEffect(() => {
    if (!subject) return
    resetFields()
  }, [subject])

  const resetFields = () => {
    formInstance.setFieldsValue({
      firstName: subject.firstName,
      lastName: subject.lastName,
      internationalPhoneNumber: subject.internationalPhoneNumber || undefined,
      language: subject.language
    })
    setFormKey(formKey === 0 ? 1 : 0)
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    formInstance.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = ({ firstName, lastName, email, internationalPhoneNumber, language }: SubjectFormData) => {
    editSubjectDetails(
      { subjectId: user.getSubjectId(), firstName, lastName, email, internationalPhoneNumber, language },
      {
        onSuccess: subject => {
          setSubject(subject)
          setUserLanguage(language)
          setIsEditingOn(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const parseLanguage = (lang: locales.ILocale) => ({ value: lang.tag, label: lang.local || lang.name })

  const countries = locales.all.reduce((acc, curr) => {
    if (!acc.find(lang => [curr.local, curr.name].includes(lang.label))) {
      acc.push(parseLanguage(curr))
    }
    return acc
  }, [])

  return (
    <div className="subject-dashboard-edit-subject">
      <div className="subject-dashboard-edit-subject__controls">
        <DatacTitle type="h1">{intlProfile('title')}</DatacTitle>
        {isEditingOn ? (
          <div>
            <Button size="large" type="default" onClick={onCancel}>
              {intl('common.cancel')}
            </Button>
            <Button size="large" type="primary" onClick={onSave}>
              {intl('common.save')}
            </Button>
          </div>
        ) : (
          <Button size="large" type="primary" onClick={onEdit}>
            {intl('common.edit')}
          </Button>
        )}
      </div>
      <ConfigProvider locale={localizedCountries(localeFromPath())}>
        <Form form={formInstance} onFinish={onSubmit} key={formKey} className="subject-dashboard-edit-subject__form">
          <DatacSettingsFormItem
            name="firstName"
            label={intlSubjects('first_name.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              disabled={!isEditingOn}
              size="large"
              placeholder={intlSubjects('first_name.placeholder')}
              maxLength={maxTitleLengthShort}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="lastName"
            label={intlSubjects('last_name.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              disabled={!isEditingOn}
              size="large"
              placeholder={intlSubjects('last_name.placeholder')}
              maxLength={maxTitleLengthShort}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="internationalPhoneNumber"
            label={intlSubjects('phone.label')}
            validate={[validatePhone(intlSubjects('phone.validation'), true)]}
          >
            <DatacPhoneInput
              disabled={!isEditingOn}
              size="large"
              placeholder={intlSubjects('phone.placeholder')}
              className="datac-form-item"
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="language"
            label={intlSubjects('language.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacSelect
              disabled={!isEditingOn}
              options={countries}
              placeholder={intlSubjects('language.placeholder')}
              showSearch
            />
          </DatacSettingsFormItem>
        </Form>
      </ConfigProvider>
    </div>
  )
}
