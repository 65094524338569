import './DeletionRequest.less'

import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { SubjectStatus, sendDeletionRequest } from '../../../requests'
import { maxTitleLengthShort, validateRequired } from '../../../validation'
import { DatacFormItem, DatacMessage, DatacModal, DatacTitle } from '../../common'
import { useSubjectDashboardStore } from '../SubjectDashboardStore'

export const DeletionRequest: React.VFC = () => {
  const intl = useScopedIntl('')
  const intlDelete = useScopedIntl('subject_dashboard.delete_account')
  const [formInstance] = Form.useForm()
  const [isSaving, setIsSaving] = useState(false)
  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [isPasswordInvalidated, setIsPasswordInvalidated] = useState(false)
  const { subject, setSubjectStatus } = useSubjectDashboardStore()

  const onSubmit = ({ password }: { password: string }) => {
    sendDeletionRequest(
      { password },
      {
        onSuccess: () => {
          setSubjectStatus(SubjectStatus.DeletionRequested)
          setIsConfirmationModalOpened(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code),
        onInvalidPassword: () => setIsPasswordInvalidated(true),
        onError: () => setIsSaving(false)
      }
    )
  }

  const onPasswordChange = () => {
    if (isPasswordInvalidated) setIsPasswordInvalidated(false)
  }

  const afterModalClose = () => {
    formInstance.resetFields()
    setFormKey(formKey === 1 ? 0 : 1)
  }

  if (!subject.isSubjectAccountDeletionEnabled || subject.status === SubjectStatus.DeletionRequested) return null

  return (
    <div className="subject-dashboard-deletion-request">
      <div className="subject-dashboard-deletion-request__controls">
        <DatacTitle type="h1">{intlDelete('title')}</DatacTitle>
        <div>
          <Button size="large" type="primary" onClick={() => setIsConfirmationModalOpened(true)}>
            {intlDelete('button')}
          </Button>
        </div>
      </div>
      <div className="subject-dashboard-deletion-request__content">{intlDelete('description')}</div>
      <DatacModal
        title={intlDelete('confirmation.title')}
        isOpened={isConfirmationModalOpened}
        onClose={() => setIsConfirmationModalOpened(false)}
        onSubmit={() => formInstance.submit()}
        submitLabel={intlDelete('confirmation.button')}
        loading={isSaving}
        afterClose={afterModalClose}
      >
        <div className="subject-dashboard-deletion-request__confirmation">
          <div className="subject-dashboard-deletion-request__confirmation__description">
            {intlDelete('confirmation.description')}
          </div>
          <Form name="delete-subject-form" onFinish={onSubmit} form={formInstance} key={formKey}>
            <DatacFormItem
              label={intlDelete('confirmation.password.label')}
              name="password"
              validate={validateRequired(intl('common.required'))}
              error={isPasswordInvalidated ? intlDelete('confirmation.password.validation.wrong') : undefined}
            >
              <Input.Password
                onChange={onPasswordChange}
                maxLength={maxTitleLengthShort}
                placeholder={intlDelete('confirmation.password.placeholder')}
                autoComplete="new-password"
              />
            </DatacFormItem>
          </Form>
        </div>
      </DatacModal>
    </div>
  )
}
